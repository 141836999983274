<template>
    <section>
        <transition name="fade" mode="out-in">
            <router-view :data="data" />
        </transition>
        <site-footer />
    </section>
</template>

<script>
    import router from '../router'
    import Footer from '@/components/Footer'
    import axios from 'axios'
    import Meta from '../mixins/Meta.js'

    export default {
        mixins: [Meta],
        components: {
            'site-footer': Footer
        },
        data() {
            return {
                data: null,
            }
        },
        mounted() {
            this.loadData();
        },
        methods: {
            loadData() {
                axios.get(this.$json('/data/careers.json'))
                    .then(response => {
                        this.data = response.data;
                        console.log('data', response.data);
                        this.setMetaData(this.data.meta);
                        // console.log(this.data);
                        this.$sectionLoaded();
                        this.$checkAgeGate(this.data.ageGate);
                    })
                    .catch(error => {
                        console.log(error);
                    })
            },
        },
    }
</script>